export default class Controls {
    constructor(type){
        this.forward = false;
        this.left = false;
        this.right = false;
        this.reverse = false;
        this.steering = 0;
        this.acceleration = 0;
        this.forwardTimeout = 0;
        
        switch(type) {
            case "KEYS":
                this.#addKeyboardListeners();
                break;
            case "DUMMY":
                this.forward = true;
                break;
        }
    }

    update() {
        
        // if (this.forwardTimeout > 0) {
        //     this.forwardTimeout--;
        //     if (this.forwardTimeout == 0) {
        //         this.forward = false;
        //     }
        // }
    }

    #addKeyboardListeners() {
        document.onkeydown = (event) => {
            switch(event.key) {
                case "a":
                    this.left = true;
                    // this.steering += 0.2;
                    // if (this.steering > 1.0) {
                    //     this.steering = 1.0;
                    // }
                    break;
                case "d":
                    this.right = true;
                    // this.steering -= 0.2;
                    // if (this.steering < -1.0) {
                    //     this.steering = -1.0;
                    // }
                    break;
                case "w":
                    this.forward = true;
                    this.forwardTimeout = 20;

                    // this.acceleration += 0.05;
                    // if (this.acceleration > 1.0) {
                    //     this.acceleration = 1.0;
                    // }
                    break;
                case "s":
                    // this.acceleration -= 0.1;
                    // if (this.acceleration < -1.0) {
                    //     this.acceleration = -1.0;
                    // }
                    this.reverse = true;
                    break;
            }
        }
        document.onkeyup = (event) => {
            switch(event.key) {
                case "a":
                    this.left = false;
                    break;
                case "d":
                    this.right = false;
                    break;
                case "w":
                    //this.acceleration = 0;
                    this.forward = false;
                    this.forwardTimeout = 200;
                    break;
                case "s":
                    //this.acceleration = 0;
                    this.reverse = false;
                    break;
            }
        }
    }

}