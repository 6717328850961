const utils = require('./utils');


export default class Sensor {
    constructor(car, rayCount = 5, rayLength = 150) {
        this.car = car;
        this.rayCount = rayCount;
        this.rayLength = rayLength;
        this.raySpread = Math.PI / 2;

        this.rays = [];
        this.readings = [];
        this.touchedCars = [];
    }

    update(roadBorders, traffic, updateSeen = false) {
        this.#castRays();

        if (updateSeen) {
            for (let i = 0; i < traffic.length; i++) {
                traffic[i].seen = false;
            }
        }

        this.readings = [];
        for (let i = 0; i < this.rays.length; i++) {
            this.readings.push(
                this.#getReading(this.rays[i], roadBorders, traffic, updateSeen)
            );
        }
    }

    #getReading(ray, roadBorders, traffic, updateSeen = false) {

        let touches = [];

        for (let i = 0; i < roadBorders.length; i++) {
            const touch = utils.getIntersection(ray[0], ray[1], roadBorders[i][0], roadBorders[i][1]);
            if (touch) {
                touch.type = 0;
                touches.push(touch);
            }
        }

        let touchedCars = [];
        for (let i = 0; i < traffic.length; i++) {
            const poly = traffic[i].polygon;
            for(let j= 0; j < poly.length; j++) {
                const touch = utils.getIntersection(ray[0], ray[1], poly[j], poly[(j+1) % poly.length]);
                if (touch) {
                    touch.type = 1;
                    touches.push(touch);
                    if (updateSeen) {
                        traffic[i].seen = true;
                    }
                }
            }
        }

        if (touches.length == 0) {
            return null;
        }
        
        const offsets = touches.map(e => e.t);
        const minOffset = Math.min(...offsets);
        //console.log(minOffset);
        return touches.find(e => e.t == minOffset);

    }

    draw(ctx, showReadings = true) {
        for(let i = 0; i < this.rays.length; i++) {

            if (showReadings) {
                let end = this.rays[i][1];

                if (this.readings[i]) {
                    end = this.readings[i]
                }

                ctx.beginPath();
                ctx.lineWidth = 2;
                ctx.strokeStyle = "yellow";
                ctx.moveTo(this.rays[i][0].x, this.rays[i][0].y);
                ctx.lineTo(end.x, end.y);
                ctx.stroke();

                ctx.beginPath();
                ctx.lineWidth = 2;
                ctx.strokeStyle = "gray";
                ctx.moveTo(this.rays[i][1].x, this.rays[i][1].y);
                ctx.lineTo(end.x, end.y);
                ctx.stroke();
            } else {


                ctx.globalAlpha = 0.2;
                ctx.beginPath();
                ctx.lineWidth = 1;
                ctx.strokeStyle = "darkgray";
                ctx.moveTo(this.rays[i][1].x, this.rays[i][1].y);
                ctx.lineTo(this.rays[i][0].x, this.rays[i][0].y);
                ctx.stroke();
                ctx.globalAlpha = 1.0;
            }
        }
    }

    #castRays() {
        this.rays = [];

        for (let i = 0; i < this.rayCount; i++) {
            const rayAngle = utils.lerp(this.raySpread / 2, -this.raySpread / 2, this.rayCount == 1 ? 0.5 : i / (this.rayCount - 1)) + this.car.angle;

            const start = {x: this.car.x, y: this.car.y};
            const end = {x: this.car.x - Math.sin(rayAngle) * this.rayLength, y:this.car.y - Math.cos(rayAngle) * this.rayLength};

            this.rays.push([start, end]);
        }
    }

}