import NeuralNetwork from './network.js'
import Controls from './controls.js'
import Sensor from './sensor.js'
import { debug } from 'webpack';

const utils = require('./utils');

export default class Car{

    constructor(x, y, width, height, controlType, maxSpeed = 5, useSpeed = false, use4xControls = false, useCarAngle = false, rayCount = 5, rayLength = 150){
        this.id = null;
        this.x = x;
        this.y = y;
        this.prevY = y;
        this.prevX = x;

        this.width = width;
        this.height = height;

        this.maxFuel = 88;
        this.speed = 0.2;
        this.maxSpeed = maxSpeed;
        this.friction = 0.1;
        this.acceleration = 0.2;

        this.life = this.maxFuel; // + Math.random() * 10;
        this.score = 0;
        this.passed = 0;
        this.followCount = 0;
        this.angle = 0;
        this.damaged = false;
        this.ghost = 0;
        this.following = false;
        this.straightLineCount = 0;
        this.seen = false;

        this.addFuelToBrain = false;
        this.addSpeedToBrain = useSpeed;
        this.addAngleToBrain = useCarAngle;

        this.useBrain = controlType == "AI" || controlType == "KEYS";
        this.feedBrain = controlType == "AI" || controlType == "KEYS";
        this.tagTraffic = controlType == "KEYS";
        this.useAcceleration = controlType == "AI" || controlType == "KEYS";
        this.use4xControls = use4xControls;
        this.offsets = [];

        if (this.useBrain || this.feedBrain) {
            this.sensor = new Sensor(this, rayCount, rayLength);

            var sensorCount = this.sensor.rayCount;

            if (this.addFuelToBrain) {
                sensorCount++;
            }

            if (this.addSpeedToBrain) {
                sensorCount++;
            }

            if (this.addAngleToBrain) {
                sensorCount++;
            }

            var outputCount = this.use4xControls ? 4 : 2;

            //console.log("Adding a brain ...");
            this.brain = new NeuralNetwork(
                [sensorCount, 8, 8, 6, outputCount]
            );


        }

        this.detail = false;
        this.color = "#9B59B6";

        this.contols = new Controls(controlType);

    }


    #createPolygon() {
        const points = [];
        const rad = Math.hypot(this.width, this.height) / 2;
        const alpha = Math.atan2(this.width, this.height);
        points.push({x: this.x - Math.sin(this.angle - alpha) * rad, y: this.y - Math.cos(this.angle - alpha) * rad });
        points.push({x: this.x - Math.sin(this.angle + alpha) * rad, y: this.y - Math.cos(this.angle + alpha) * rad });
        points.push({x: this.x - Math.sin(Math.PI+this.angle - alpha) * rad, y: this.y - Math.cos(Math.PI+this.angle - alpha) * rad });
        points.push({x: this.x - Math.sin(Math.PI+this.angle + alpha) * rad, y: this.y - Math.cos(Math.PI+this.angle + alpha) * rad });

        return points;
    }


    #createFuelGuage() {
        const points = [];

        var level = this.life;

        if (level > this.maxFuel) {
            level = this.maxFuel;
        }

        const rad = Math.hypot(this.width, level / 2) / 2;
        const alpha = Math.atan2(this.width, level / 2);

        points.push({x: this.x - Math.sin(this.angle - alpha) * rad, y: this.y - Math.cos(this.angle - alpha) * rad });
        //points.push({x: this.x - Math.sin(this.angle + alpha) * rad, y: this.y - Math.cos(this.angle + alpha) * rad });
        //points.push({x: this.x - Math.sin(Math.PI+this.angle - alpha) * rad, y: this.y - Math.cos(Math.PI+this.angle - alpha) * rad });

        //this.y + this.height / 2 - this.life - 2
        
        points.push({x: this.x - Math.sin(Math.PI+this.angle + alpha) * rad, y: this.y - Math.cos(Math.PI+this.angle + alpha) * rad });

        return points;

    }

    #assesDamage(roadBorders, traffic) {
        for(let i = 0; i < roadBorders.length; i++) {
            if (utils.polysIntersect(this.polygon, roadBorders[i])) {
                return true;
            }
        }
        for(let i = 0; i < traffic.length; i++) {
            if (utils.polysIntersect(this.polygon, traffic[i].polygon)) {
                return true;
            }
        }
        return false;
    }

    #calculateScore(traffic) {

        let score = 0;
        for(let i = 0; i < traffic.length; i++) {
            if (traffic[i].y > this.y) {
                score++;
            }
        }

        return score;
    }

    initBrainX(roadBorders, traffic) {
        console.error("initBrain Should not be used ?")
    }

    initBrainX(roadBorders, traffic) {
        if (this.sensor && this.useBrain) {

            //debugger;
            this.sensor.update(roadBorders, traffic);
            const offsets = this.sensor.readings.map(
                s => s==null ? 0 : 1-s.t
            )

            if (this.addFuelToBrain) {
                var fuelVal = this.life;
                if (fuelVal > this.life) {
                    fuelVal = this.life;
                }
                offsets.push(fuelVal / this.maxFuel);
            }

            if (this.addSpeedToBrain) {
                offsets.push(this.speed / this.maxSpeed);
            }

            //debugger;
            const outputs = NeuralNetwork.feedForward(offsets, this.brain);

            if (this.use4xControls) {

                this.set4xControls(outputs);


            } else {
                this.contols.acceleration = outputs[0];
                // if (outputs[0] < 0) {
                //     console.log("Braking!", this.id, outputs[0]);
                // }
                this.contols.steering = outputs[1];
                //console.log(outputs);
            }
        }
    }

    set4xControls(outputs) {


        if (this.useAcceleration) {
            if (outputs[3] > 0) {
                this.contols.steering += 0.2;
                if (this.contols.steering > 1.0) {
                    this.contols.steering = 1.0;
                }
            }
            if (outputs[2] > 0) {
                this.contols.steering -= 0.2;
                if (this.contols.steering < -1.0) {
                    this.contols.steering = -1.0;
                }
            }
            
            if (outputs[0] > 0) {
                this.contols.acceleration += 0.2;
                if (this.contols.acceleration > 1.0) {
                    this.contols.acceleration = 1.0;
                }
            }
            if (outputs[1] > 0) {
                this.contols.acceleration -= 0.2;
                if (this.contols.acceleration < -1.0) {
                    this.contols.acceleration = -1.0;
                }
            }
        } else {
            if (outputs[3] > 0.1) {
                this.contols.right = true;
                //this.life += 0.01;
            } else {
                this.contols.right = false;
                //this.life += 0.01;
            }

            if (outputs[2] > 0.1) {
                this.contols.left = true;
            } else {
                this.contols.left = false;
            }
            
            if (outputs[0] > 0.1) {
                this.contols.forward = true;
            } else {
                this.contols.forward = false;
            }
            if (outputs[1] > 0.1) {
                this.contols.reverse = true;
            } else {
                this.contols.reverse = false;
            }
        }


        //var acc_plus_brake = (outputs[0] + outputs[1]) / 2;

       // this.contols.acceleration -= acc_plus_brake;

        // if (acc_plus_brake > 0.2) {
        //     this.contols.acceleration += 0.1;
        //     if (this.contols.acceleration > 1.0) {
        //         this.contols.acceleration = 1.0;
        //     }
        // } else if (acc_plus_brake < -0.2) {
        //     this.contols.acceleration -= 0.1;
        //     if (this.contols.acceleration < -1.0) {
        //         this.contols.acceleration = -1.0;
        //     }
        // } else {

        // }

    }

    update(roadBorders, traffic, step = 0) {
        
        if (this.sensor) {
            this.life -= 0.02;
        }

        if (this.life < 0)
        {
            this.damaged = true;
        }

        if (!this.damaged) {

            this.#move();
            this.contols.update();
            this.polygon = this.#createPolygon();

            this.damaged = this.#assesDamage(roadBorders, traffic);
            //const newScore = this.#calculateScore(traffic);

            if (this.feedBrain) {

                if (this.y >= this.prevY) {
                    this.life -= 0.02;
                }
                
                this.prevY = this.y;

                if (this.maxSpeed > 2) {
                    if (Math.round(this.x) == this.prevX) {
                        this.straightLineCount++;    
                    }

                    if (this.straightLineCount > 20) {
                        this.life -= 3.0;
                        this.straightLineCount = 0;
                        //console.log("Too straight ;)", this.id, this.life)
                    }
                }
                
                this.prevX = Math.round(this.x);
            
                const newPassed = this.#calculateScore(traffic);
                if (newPassed > this.passed) {
                    this.life += 20;
                    // if (this.life > this.maxFuel) {
                    //     this.life = this.maxFuel;
                    // }
                    this.passed = newPassed;
                }
                //debugger;
                this.score = Math.round((this.y - 100) / 100) * -1;
            }


            // if (this.speed < 0) {
            //     this.followCount++;
            // }

            // if (this.followCount > 5) {
            //     this.life -= 0.2;
            //     this.followCount -= 5;
            // }

            if (this.sensor) {
                this.sensor.update(roadBorders, traffic, this.tagTraffic);
            }

            if (this.sensor && this.feedBrain) {

                //debugger;
                //this.sensor.update(roadBorders, traffic);
                //console.log(this.sensor.readings);

                this.offsets = this.sensor.readings.map(
                    s => s==null ? 0 : 1-(s.t)
                )

                if (this.addFuelToBrain) {
                    var fuelVal = this.life;

                    if (fuelVal > this.maxFuel) {
                        fuelVal = this.maxFuel;
                    }

                    this.offsets.push(fuelVal / this.maxFuel);
                }

                if (this.addSpeedToBrain) {
                    this.offsets.push(this.speed / this.maxSpeed);
                }

                if (this.addAngleToBrain) {
                    //console.log(this.angle);
                    this.offsets.push(this.angle * -1);
                }

                //debugger;
                const outputs = NeuralNetwork.feedForward(this.offsets, this.brain);

                if (this.contols.forward != 0 || this.contols.reverse!= 0 || this.contols.left != 0 || this.contols.right != 0) {

                    var actuals = [];
                    actuals[0] = outputs[0];
                    actuals[1] = outputs[1];

                    if (this.contols.forward != 0) {
                        actuals[0] = 1;
                    } else if (this.contols.reverse!= 0) {
                        actuals[0] = -1;
                    }

                    if ( this.contols.left != 0) {
                        actuals[1] = 1;
                    } else if (this.contols.right != 0) {
                        actuals[1] = -1;
                    }

                    //var actuals = [this.contols.forward ? 1 : (this.contols.reverse ? -1 : 0), this.contols.left ? 1 : (this.contols.right ? -1 : 0)];
                    NeuralNetwork.backPropagate(actuals, this.brain);
                    }

                if (this.useBrain) {
                    //console.log(this.use4xControls);
                    if (this.use4xControls) {

                        this.set4xControls(outputs);

                        //console.log (this.acceleration, this.steering);

                    } else {
                        this.contols.acceleration = outputs[0];
                        // if (outputs[0] < 0) {
                        //     console.log("Braking!", this.id, outputs[0]);
                        // }
                        this.contols.steering = outputs[1];
                        //console.log(outputs);
                    }
                }

                
            }
        } else {
            this.ghost++;
        }

        
    }

    draw(ctx, drawSensor = false) {

        if (this.damaged && this.ghost > 200) {
            return;
        }

        
        if (this.damaged) {
            ctx.fillStyle = "gray";
            ctx.globalAlpha = 0.2;
        } else {
            ctx.fillStyle = this.color;
        }
        
        if (this.brain) {
            //debugger;
            ctx.strokeStyle = "white";
        } else {
            ctx.strokeStyle = "red";
        }

        //debugger;

        if (this.polygon && this.polygon.length > 1) {
            ctx.beginPath();
            ctx.moveTo(this.polygon[0].x, this.polygon[0].y);
            for(let i=1; i < this.polygon.length; i++) {
                ctx.lineTo(this.polygon[i].x, this.polygon[i].y);
            }
            ctx.closePath();

            //ctx.strokeStyle = "#000000";
            ctx.lineWidth = 2;
            ctx.stroke();

            ctx.fill();

            if (!this.damaged) {
                if (this.sensor) {
                    ctx.lineWidth = 5;
                    ctx.strokeStyle = "green";
                    if (this.life < 20) {
                        ctx.strokeStyle = "orange";
                    }
                    if (this.life < 10) {
                        ctx.strokeStyle = "red";
                    }

                    if (this.life > this.maxFuel) {
                        ctx.strokeStyle = "#3498DB";
                    }

                    var guage = this.#createFuelGuage()


                    ctx.beginPath();
                    //ctx.moveTo(this.x - 10, this.y + this.height / 2 - this.life - 2);
                    //ctx.lineTo(this.x - 10, this.y + this.height / 2 - 2);

                    ctx.moveTo(guage[0].x - 5, guage[0].y);
                    ctx.lineTo(guage[1].x - 5, guage[1].y);

                    ctx.stroke();
                }

                ctx.beginPath();
                ctx.textAlign="center";
                ctx.textBaseline="middle";
                ctx.fillStyle="white";
                ctx.strokeStyle="white";
                ctx.font="8px Arial";
                
                if (this.brain) {
                    ctx.fillText(this.id,this.x,this.y + 8);
                }

                if (this.score) {
                    //debugger;
                    ctx.fillText(this.score,this.x,this.y + 17);
                    //ctx.lineWidth=0.5;
                    //ctx.strokeText(this.id,this.x,this.y);
                }
            }

        }
        ctx.globalAlpha = 1.0;

        if (this.sensor && !this.damaged) {
            this.sensor.draw(ctx, drawSensor);
        }
    }

    #move() {


        if (this.useAcceleration) {

            this.speed += this.contols.acceleration;
            //console.log(this.speed);
            //console.log("Max Speed", this.contols.acceleration, this.speed, this.maxSpeed);

            // if (this.useBrain) {
            //     console.log(this.speed);
            // }

            if (this.speed > this.maxSpeed) {
                this.speed = this.maxSpeed;
                //this.contols.acceleration = 0;
            }
            if (this.speed < -this.maxSpeed/2) {
                this.speed = -this.maxSpeed/2;
                //this.contols.acceleration = 0;
            }

            //if (this.speed < 0) {
            //    this.speed += this.friction;
            //}

            //if (Math.abs(this.speed) < this.friction) {
            //    this.speed = 0;
            //}

            if (this.speed != 0) {
                const flip = this.speed > 0 ? 1 : -1;
                this.angle += 0.01 * flip * this.contols.steering;
            }


            // Apply wheel recentering

            // if (this.angle > 0) {
            //     this.angle -= 0.001;
            //     if (this.angle < 0) {
            //         this.angle = 0;
            //     }
            // } else if (this.angle < 0) {
            //     this.angle += 0.001;
            //     if (this.angle > 0) {
            //         this.angle = 0;
            //     }
            // }






            // Recenter coltrols

            if (this.contols.acceleration > 0) {
                this.contols.acceleration -= 0.01;
                if (this.contols.acceleration < 0) {
                    this.contols.acceleration = 0;
                }
            } else if (this.contols.acceleration < 0) {
                this.contols.acceleration += 0.01;
                if (this.contols.acceleration > 0) {
                    this.contols.acceleration = 0;
                }
            }

            
            if (this.contols.steering > 0) {
                this.contols.steering -= 0.02 * this.speed;
                if (this.contols.steering < 0) {
                    this.contols.steering = 0;
                }
            } else if (this.contols.steering < 0) {
                this.contols.steering += 0.02 * this.speed;
                if (this.contols.steering > 0) {
                    this.contols.steering = 0;
                }
            }


        } else {

            if (this.contols.forward) {
                this.speed += this.contols.forward;
            }
        
            if (this.contols.reverse) {
                this.speed -= this.contols.reverse;
            }

            if (this.speed > this.maxSpeed) {
                this.speed = this.maxSpeed;
            }
            if (this.speed < -this.maxSpeed/2) {
                this.speed = -this.maxSpeed/2;
            }

            if (this.speed != 0) {
                const flip = this.speed > 0 ? 1 : -1;
                if (this.contols.right) {
                    this.angle -= 0.01 * flip;
  
                }
                if (this.contols.left) {
                    this.angle += 0.01 * flip;

                }
            }

            if (this.angle < -0.5) {
                this.angle = -0.5;
            }
            if (this.angle > 0.5) {
                this.angle = 0.5;
            }
        }

        // Apply friction 
        if (this.speed > 0) {
            this.speed -= this.friction;
            if (this.speed < 0) {
                this.speed = 0;
            }
        } else if (this.speed < 0) {
            this.speed += this.friction;
            if (this.speed > 0) {
                this.speed = 0;
            }
        }

        // if (this.id == "Human") {
        //     console.log(this.speed);
        //     }

        this.x -= Math.sin(this.angle) * this.speed;
        this.y -= Math.cos(this.angle) * this.speed;



    }

}