//import axios from 'axios';

export function lerp(A, B, t) {
    return A + (B - A) * t;
}

export function ReLU(x) {
    return Math.max(0, x);
};

export function sigmoid(x) {
  return 1.0 / (1.0 + Math.pow(Math.E, -d));
};


export function getIntersection(A,B,C,D){
    const tTop  = (D.x-C.x)*(A.y-C.y)-(D.y-C.y)*(A.x-C.x);
    const uTop  = (C.y-A.y)*(A.x-B.x)-(C.x-A.x)*(A.y-B.y);
    const bottom =  (D.y-C.y)*(B.x-A.x)-(D.x-C.x)*(B.y-A.y);


    if(bottom != 0){
       
        const t= tTop/bottom;
        const u= uTop/bottom;
        if(t>0 && t<=1 && u>0 && u<=1){
            return {
                x:lerp(A.x,B.x,t),
                y:lerp(A.y,B.y,t),
                t:t,
    
            }
        }
    }

    
    return null;

}


export function polysIntersect(poly1, poly2) {
    for (let i = 0; i < poly1.length; i++) {
        for (let j = 0; j < poly2.length; j++) {
            const touch = getIntersection(
                poly1[i],
                poly1[(i+1) % poly1.length],
                poly2[j],
                poly2[(j+1) % poly2.length]
                );
            if (touch) {
                return true;
            }
        }    
    }

    return false;
}

export function drawDot(point, label, isRed){
    carCtx.beginPath();
    carCtx.fillStyle =isRed? 'red' : 'white';
    carCtx.arc(point.x, point.y, 10, 0, Math.PI*2)
    carCtx.fill();
    carCtx.stroke();
    carCtx.fillStyle = 'black';
    carCtx.textAlign = 'center';
    carCtx.textBaseline = 'middle';
    carCtx.font = 'bold 14px Arial';

    carCtx.fillText(label, point.x, point.y)
}

export async function postData(url = '', data = {}, token = '') {
    // Default options are marked with *
    console.log('postData', url, token, data);
    try {


        const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer-when-downgrade', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        });

        //console.log("response found");
        return response.json(); // parses JSON response into native JavaScript objects
        
      } catch(error) {
        //debugger;
        //console.log(error);
        return null;
    }
  }

export async function putData(url = '', data = {}, token = '') {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  export async function getData(url = '', token = '') {
    
    console.log('getData', url, token);
    //debugger;
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer'
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }  

  // export async function getDataY(url = '', token = '') {
    
  //   try {
  //     const response = await axios({
  //       method: 'GET',
  //       mode: 'no-cors',
  //       cache: 'no-cache',
  //       credentials: 'same-origin',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + token
  //       },
  //       redirect: 'follow', // manual, *follow, error
  //       referrerPolicy: 'strict-origin-when-cross-origin'
  //     });
  //     console.log("AXIOS RESPONSE", response);
  //     return response;
  //   } catch (error) {
  //     console.error("AXIOS ERROR", error);
  //   }
  // }