//import CONFIG from './config.js'
import Car from './car.js'
import Road from './road.js'
import Visualizer from './visualizer.js'
import NeuralNetwork from './network.js'
import './style.css'
//import { sortAndDeduplicateDiagnostics } from 'typescript'
//import axios from 'axios';

const utils = require('./utils');

const carCanvas = document.getElementById("carCanvas");
carCanvas.width = 200;

const networkCanvas = document.getElementById("networkCanvas");
networkCanvas.width = 400;

const starterCanvas = document.getElementById("starterCanvas");
starterCanvas.width = 400;

const maxScoreDiv = document.getElementById("maxScore");
const generationDiv = document.getElementById("generation");
const verticalInfoDiv = document.getElementById("verticalInfo");
const generationNameSpan = document.getElementById("generationName");
const liveCountSpan = document.getElementById("liveCount");
const legendDiv = document.getElementById("legend");
const rllegendDiv = document.getElementById("rllegend");
const leadCarDiv = document.getElementById("leadCar");
const competitionDiv = document.getElementById("competition");
const buttonSave = document.getElementById("buttonSave");
const buttonDiscard = document.getElementById("buttonDiscard");
const buttonPlayOrPause = document.getElementById("buttonPlayOrPause");
const buttonSlower = document.getElementById("buttonSlower");
const buttonFaster = document.getElementById("buttonFaster");
const fpsDiv = document.getElementById("fps");


const dropDownListCarCount = document.getElementById("carCount");

buttonSave.addEventListener("click", (event) => save());
buttonDiscard.addEventListener("click", (event) => discard());
buttonPlayOrPause.addEventListener("click", (event) => playOrPause());
buttonSlower.addEventListener("click", (event) => decreaseFps());
buttonFaster.addEventListener("click", (event) => increaseFps());

buttonLogin.addEventListener("click", (event) => login());

//const nnApiBaseUrl = "https://localhost:55740";
//const nnApiBaseUrl = "http://192.168.1.50:5038";
//const nnApiBaseUrl = "http://nnapi.k8s.local";

const nnApiBaseUrl = API_BASE_URL;

var local = true;
var useDemoSandbox = false;
const GameMode = GAME_MODE;
//const GameMode = "C";
//const GameMode = "RL";

var fps = 30;

var useCarSpeed = false;
var useCarAngle = false;

var token = '';
var use4xControls = false;
var sbId = "demo1";


function reset() {

    cameraY = 100;
    cameraSpeed = 0;
    if (local) {
        compete();
    }
}



const bestBrainKey = "bestBrain-v2";

const carCtx = carCanvas.getContext("2d");
const networkCtx = networkCanvas.getContext("2d");
const starterCtx = starterCanvas.getContext("2d");


const road = new Road(carCanvas.width/2, carCanvas.width * 0.9, 5);

var cars = [];
var bestCar = null;
var followCar = null;
var liveCount = 0;
var traffic = [];
var frontCar;
var bestScore = 20;
var bestPasses = 2;
var trafficSeed = "";
var bestBrain = null;
var generation = 0;
var paused = false;

var cameraY = 100;
var cameraSpeed = 0;
var prevCameraSpeed = 0;
var prevCameraZone = 0;
var starterBrain;

var participants = [];


if (localStorage.getItem("fps")) {
    var fps_string = localStorage.getItem("fps");
    fps = parseInt(fps_string)
}
fpsDiv.innerText = fps;




function selectColor(colorNum, colors){
    if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
    return "hsl(" + (colorNum * (360 / colors) % 360) + ",80%,20%)";
}

function selectTrafficColor(){

    return selectColor(Math.floor(Math.random() * 32), 32);
}

function makeTraffic(baseSpeed = 2) {

    traffic = [];

    for (let i = 0; i < 20; i++) {
        
        var lane = Math.floor(Math.random() * 5);
        var speed = Math.random();
        var car = new Car(road.getLaneCenter(lane), -200-(i * 350), 30, 50, "DUMMY", baseSpeed + speed);
        car.color = selectTrafficColor();
        traffic.push(car);
        
        var lane2 = lane;

        while (lane2 == lane) {
            lane2 = Math.floor(Math.random() * 5);
        }
        var speed = Math.random();
        var car = new Car(road.getLaneCenter(lane2), -180-(i * 400), 25, 40, "DUMMY", baseSpeed + speed);
        car.color = selectTrafficColor();

        traffic.push(car);
    }


    trafficSeed = JSON.stringify(traffic.map(({ x, y, maxSpeed, color }) => ({x, y, maxSpeed, color})))
}


function restoreTraffic(savedTraffic) {

    traffic = [];

    var savedTrafficObject = JSON.parse(savedTraffic);
    for (let i = 0; i < savedTrafficObject.length; i++) {
        var o = savedTrafficObject[i];
        var car = new Car(o.x, o.y, 30, 50, "DUMMY", o.maxSpeed);
        car.color = o.color;
        traffic.push(car);
    }

    trafficSeed = savedTraffic;
}


//animate();

function save() {
    console.log(trafficSeed);
    localStorage.setItem(bestBrainKey, JSON.stringify(bestCar.brain));
    localStorage.setItem("bestTraffic", trafficSeed);
}

function discard() {
    localStorage.removeItem(bestBrainKey);
    localStorage.removeItem("bestTraffic");
}

function playOrPause() {
    if (paused) {
        paused = false;
        buttonPlayOrPause.innerText = "Pause";
    } else {
        paused = true;
        buttonPlayOrPause.innerText = "Play";
    }
}


function increaseFps() {

    fps += 6;

    if (fps > 60) {
        fps = 60;
    }
    
    fpsDiv.innerText = fps;
    localStorage.setItem('fps', fps);
}

function decreaseFps() {

    fps -= 6;

    if (fps < 6) {
        fps = 6;
    }
    
    fpsDiv.innerText = fps;
    localStorage.setItem('fps', fps);
}


//buttonSlower.addEventListener("click", (event) => increaseFps());
//buttonFaster.addEventListener("click", (event) => decreaseFps());



function login() {

    let uri = nnApiBaseUrl + "/login";

    utils.postData(uri, {
        Username : "demo1",
        Password : "Passw0rd"
    }).then((tokenData) => {
        console.log(tokenData); // JSON data parsed by `data.json()` call
        if (tokenData) {
           const td = tokenData;
           const t = td.token;
           const r = td.refreshToken;

           localStorage.setItem('token', t);
           localStorage.setItem('refresh-token', r);
           token = t;
           }

    });


}

function refreshToken() {

    let refreshToken = localStorage.getItem('refresh-token');
    let uri = nnApiBaseUrl + `/refresh?refreshToken=${refreshToken}`;

    fetch (uri, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
        'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer'
    })
    .then((response) => {
        //debugger;
        if (response.ok) {
            return response.json();
        }
    })
    .then((tokenData) => {
        console.log(tokenData); // JSON data parsed by `data.json()` call
        if (tokenData) {
            const td = tokenData;
            const t = td.token;
            const r = td.refreshToken;
 
            localStorage.setItem('token', t);
            localStorage.setItem('refresh-token', r);
            token = t;
 
           }

    });


}



function compete() {

    console.log("Resetting ...");


    //if (cars.length != N) {
    //    cars = generateCars(N);
    //}

    // var bestBrain = JSON.stringify(new NeuralNetwork(
    //     [cars[0].sensor.rayCount, 6, 4]
    // ));
    

    if (bestCar) {
        console.log("We have a best car ...");
        if (bestCar.passed > bestPasses && bestCar.score > bestScore) {
            bestBrain = JSON.stringify(bestCar.brain);
            bestScore = bestCar.score;
            bestPasses = bestCar.passed;
            console.log("New best score", bestScore, bestPasses);
        } else {
            if (!bestBrain) {
                bestBrain = localStorage.getItem(bestBrainKey);
            }
        }
    } else {
        console.log("No best car ...");
        if (localStorage.getItem(bestBrainKey)) {
            bestBrain = localStorage.getItem(bestBrainKey);
        }
    }

    leadCarDiv.innerHTML = "";
    maxScoreDiv.innerHTML = bestScore + " / " + bestPasses;

    if (GameMode == "T") {
        
        var carCount = Number(dropDownListCarCount.value);
        if (bestBrain) {
            cars = generateCars(carCount / 2);
        } else {
            cars = generateCars(carCount);
        }

        for (let i = 0; i < cars.length; i++) {
            if (bestBrain) {
                if (i != 0) {
                    if (i < cars.length * 0.1) { // 1st 60% - Slightly Mutated
                        cars[i].brain = JSON.parse(bestBrain);
                        NeuralNetwork.mutate(cars[i].brain, 0.01);
                        cars[i].color = "#943126"; // brownish
                    } else if (i < cars.length * 0.6) { // 1st 60% - Slightly Mutated
                        cars[i].brain = JSON.parse(bestBrain);
                        NeuralNetwork.mutate(cars[i].brain, 0.02);
                        cars[i].color = "#3498DB"; // blueish
                    } else if (i < cars.length * 0.8) { // 40% to 80% - Mutated more
                        cars[i].brain = JSON.parse(bestBrain);
                        NeuralNetwork.mutate(cars[i].brain, 0.03);
                        cars[i].color = "#138D75"; // greenish
                    } else if (i < cars.length * 0.9) { // 80% to 90% - Mutated more
                        cars[i].brain = JSON.parse(bestBrain);
                        NeuralNetwork.mutate(cars[i].brain, 0.05);
                        cars[i].color = "#04f200"; // yellow-ish - Mutated more
                    } else { // last 10% - new babies
                        cars[i].color = "#9B59B6"; 
                        //"#D35400"; // orange-ish
                    }
                }
                else {
                    cars[i].brain = JSON.parse(bestBrain);
                    cars[i].color = "#000"; // Not mutated - Gray-ish
                }
            } else {
                // cars[i].brain = new NeuralNetwork(
                //     [cars[i].sensor.rayCount, 6, 4]
                // );
                cars[i].color = "#9B59B6"; 
            }
    
        }

        if (localStorage.getItem("bestTraffic")) {
            restoreTraffic(localStorage.getItem("bestTraffic"));
        } else {
            if (trafficSeed != '') {
                restoreTraffic(trafficSeed);
            } else {
                makeTraffic();
            }
        }

    } else if (GameMode == "C") {

        makeTraffic();

        cars = [];
        var html = "";
        for (let i = 0; i < participants.length; i++) {
            
            var car = new Car(road.getLaneCenter(2), 100, 30, 50,"AI", 5, useCarSpeed, use4xControls);
            car.id = participants[i].id;
            car.color = participants[i].color;
            car.brain = participants[i].brain;
            //car.initBrain();
            cars.push(car);

            html += "<span style='background-color: " + participants[i].color + ";' >&nbsp;</span>&nbsp;" + participants[i].id + ": " + participants[i].score + "<br/>";

        }

        for (let i = 0; i < traffic.length; i++) {
            traffic[i].update(road.borders, []);    
        }

        for (let i = 0; i < cars.length; i++) {
            cars[i].initBrain(road.borders, traffic);   
        }

        
        competitionDiv.innerHTML = html;


    } else if (GameMode == "RL") {

        if (generation == 0) {

            cars = [];
            var car = new Car(road.getLaneCenter(2), 100, 30, 50,"KEYS", 1, useCarSpeed, use4xControls, useCarAngle, 5, 300);
            car.color = "#000000";
            car.id = "Human";
            cars.push(car);
            followCar = car;
            bestBrain = JSON.stringify(car.brain);


            starterBrain = JSON.parse(bestBrain);

            car = new Car(road.getLaneCenter(2), 100, 30, 50,"AI", 1, useCarSpeed, use4xControls, useCarAngle, 5, 300);
            car.color = "#3498DB";
            car.brain = JSON.parse(bestBrain);
            car.id = "RL0";
            cars.push(car);



            makeTraffic(0);
            competitionDiv.innerHTML = "Re-inforcement Learning";

        } else {

            car = cars[0];
            bestBrain = JSON.stringify(car.brain);

            cars = [];
            car = new Car(road.getLaneCenter(2), 100, 30, 50,"KEYS", 1, useCarSpeed, use4xControls, useCarAngle, 5, 300);
            car.brain = JSON.parse(bestBrain);
            car.color = "#000000";
            car.id = "Human";
            cars.push(car);
            competitionDiv.innerHTML = "Re-inforcement Learning";
            followCar = car;

            car = new Car(road.getLaneCenter(2), 100, 30, 50,"AI", 1, useCarSpeed, use4xControls, useCarAngle, 5, 300);
            car.color = "#3498DB";
            car.brain = JSON.parse(bestBrain);
            car.id = "RL" + (generation);
            cars.push(car);

            if (trafficSeed != '') {
                restoreTraffic(trafficSeed);
            } else {
                makeTraffic(0);
            }

        }

        console.log(car.brain);
    }

    if (cars.length > 0) {
        frontCar = cars[0];
        bestCar = cars[0];
    }


    generation++;
    generationDiv.innerHTML = generation;


}

function generateCars(N) {
    const cars = [];
    for (let i = 0; i < N; i++) {
        //var lane = Math.floor(Math.random() * 5);
        var lane = 2;
        var car = new Car(road.getLaneCenter(lane), 100, 30, 50,"AI", 5, useCarSpeed, use4xControls, useCarAngle);
        car.id = i;
        car.color = "black";
        cars.push(car);
    }
    return cars;
}



let lastTime = null;
let nextTime = null;

let accumulator = 0;
let lastDraw = 0;

var _frameCallback = (millis) => {

    var step = 1/fps;

    if (lastTime !== null) {

        if (!paused) {
            const diff = (millis - lastTime) / 1000;
            accumulator += diff;
            var catchup = 60;
            while (accumulator > step && catchup > 0) {

                if (true) {
                    
                    liveCount = update(step);
                    if (liveCount <= 0)
                    {
                        lastTime = null;
                        accumulator = 0;

                        if (GameMode == "C") {

                            frontCar = cars.find(c => c.y == Math.min(...cars.map(c => c.y)));
                            if (frontCar) {
                                var participant = participants.find(p => p.id == frontCar.id);
            
                                if (participant) {
                                    participant.score++;
                                }  
                            }
                        }

                        reset();

                        update(step);
                    }

                }

                accumulator -= step;
                catchup--;


            }

            liveCountSpan.innerHTML = liveCount;
        }
        draw(millis, catchup <= 0);
    }
    updateCamera();
    lastTime = millis;
    requestAnimationFrame(_frameCallback);
};

var started = false;

var _networkFrameCallback = (millis) => {


    let uri = nnApiBaseUrl + "/sb"; // + sbId; // + "?millis=" + millis;

    if (lastTime !== null) {

        // const diff = (millis - lastTime) / 1000;
        // accumulator += diff;
        
        // while (accumulator > step) {


        //     accumulator -= step;
            
        // }

        if (millis > nextTime) {
            //debugger;
            utils.getData(uri, token).then((sb) => {
                //console.log(sb.neuralNetwork); // JSON data parsed by `data.json()` call
                followCarY = sb.cameraY;
                drawSandbox(sb);
                liveCountSpan.innerHTML = sb.liveCount;
                maxScoreDiv.innerHTML = sb.cars.length;
                });

            nextTime = millis + 5000; //step * 50;
        }
    }


    updateCamera();
    lastTime = millis;
    requestAnimationFrame(_networkFrameCallback);
};


function newSanbox() {
    
    var x = carCanvas.width / 2;
    var w = carCanvas.width * 0.9;

    if (useDemoSandbox) {
        sbId = "demo1";
        //debugger;
        requestAnimationFrame(_networkFrameCallback);
    } else {

        let uri = nnApiBaseUrl + "/login";

        utils.postData(uri, {
            Username : "demo1",
            Password : "Passw0rd"
        }).then((sb) => {
            console.log(sb); // JSON data parsed by `data.json()` call
            if (sb) {

                var tokenValue = sb;
                localStorage.setItem('token', tokenValue.token);
                token = tokenValue.token;
                // generationDiv.innerHTML = "Starting...";
                // drawSandbox(sb);
                // requestAnimationFrame(_networkFrameCallback);
                // } else {
                //     generationDiv.innerHTML = "Error...";
                //     window.setTimeout(newSanbox, 5000);

                uri = nnApiBaseUrl + "/sb?gameMode=T&x="+x+"&width="+w+"&laneCount=5&carCount=500&mutateCount=250";

                generationDiv.innerHTML = "Initialising...";
                //debugger;
                utils.postData(uri, {}, token).then((sb) => {
                    console.log(sb); // JSON data parsed by `data.json()` call
                    if (sb) {
                        generationDiv.innerHTML = "Starting...";
                        drawSandbox(sb);
                        requestAnimationFrame(_networkFrameCallback);
                        } else {
                            generationDiv.innerHTML = "Error...";
                            window.setTimeout(newSanbox, 5000);
                        }


                    });


                }

        });




        
    }
}

// const { fetch: originalFetch } = window;

// window.fetch = async (...args) => {
//     let [resource, config ] = args;
    
//     // request interceptor here
//     try {
//         const response = await originalFetch(resource, config);
        
//         if (response.ok) {
//             return response;
//         }



//         // response interceptor here
//         if (!response.ok && response.status === 404) {
//             // 404 error handling
//             return Promise.reject(response);
//         }

//         if (!response.ok && response.status === 401) {
//             // 401 error handling

//             console.log("AUTH ERROR");

//             return Promise.reject(response);
//         }

//         return Promise.reject(response);

//     } catch (err) {
//         //debugger;
//         console.log("AUTH EREXCEPTION", err);
//         return Promise.reject("Auth Error");
//     }

// };



// const axiosApiInstance = axios.create();

// // Request interceptor for API calls
// axiosApiInstance.interceptors.request.use(
//   async config => {
//     config.headers = { 
//       'Authorization': `Bearer ${token}`,
//       'Accept': 'application/json',
//       'Content-Type': 'application/x-www-form-urlencoded'
//     }
//     return config;
//   },
//   error => {
//     Promise.reject(error)
// });

// // Response interceptor for API calls
// axiosApiInstance.interceptors.response.use((response) => {
//   return response
// }, async function (error) {
//   const originalRequest = error.config;
//   if (error.response.status === 401 && !originalRequest._retry) {
//     originalRequest._retry = true;
//     const access_token = await refreshAccessToken();            
//     axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//     return axiosApiInstance(originalRequest);
//   }
//   return Promise.reject(error);
// });

// function refreshAccessToken() {
//     console.log ("REFRESGIN TOOOOKKKKEEEEENNN should be here");
// }




function setupCompetition() {

    participants = [];
    // Get participants from API

    if (token) {
        let uri = nnApiBaseUrl + "/participants";

        // const controler = "participants";
        // const endpoint = new URL(`https://localhost:52351/${controler}`);

        // console.log(endpoint);

        try {
            fetch (uri, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer'
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                if (response.status === 401) {

                    refreshToken();
                    generationDiv.innerHTML = "Refreshed token...";
                    window.setTimeout(setupCompetition, 5000);
                    return;
                    }


                console.log("ERROR RESPONSE=", response);
            })
            .then((json) => {
                console.log("JSON=", json);

                if (json) {

                    participants = json;

                    for (let i = 0; i < participants.length; i++) {
                        participants[i].score = 0;
                    }

                    reset();
                    requestAnimationFrame(_frameCallback);

                } else {
                    generationDiv.innerHTML = "Waiting...";
                    window.setTimeout(setupCompetition, 5000);
                }

            }).catch((error) => { 
                console.log("ERROR", error);
                token = '';
                generationDiv.innerHTML = "Invalid login 1...";
                window.setTimeout(setupCompetition, 5000);
            });

        } catch(error) {
            //debugger;
            console.log(error);
            token = '';
            generationDiv.innerHTML = "Invalid login 2...";
            window.setTimeout(setupCompetition, 5000);
        }

    }
    else {
        generationDiv.innerHTML = "Please log in...";
        window.setTimeout(setupCompetition, 5000);
    }


}




var followCarY = 100;

function updateCamera() {

    if (local) {

        
        if (followCar) {

            //console.log("FollowCar Speed", followCar.speed, "maxSpeed", followCar.maxSpeed);

            if (cameraY < followCar.y) {
                cameraSpeed += 0.05;
            }
            if (cameraY > followCar.y) {
                cameraSpeed -= 0.05;
            }

            if (Math.abs(cameraY - followCar.y) < 100) {
                cameraSpeed = cameraSpeed * 0.98;
                //console.log("Dampening...", cameraSpeed, Math.abs(cameraY - followCar.y));
            }

            if (Math.abs(cameraY - followCar.y) < 50) {
                cameraSpeed = cameraSpeed * 0.998;
                //console.log("Stopping...", cameraSpeed, Math.abs(cameraY - followCar.y));
            }
        }
    } else {

        if (cameraY < followCarY) {
            cameraSpeed += 0.05;
        }
        if (cameraY > followCarY) {
            cameraSpeed -= 0.05;
        }

        if (Math.abs(cameraY - followCarY) < 100) {
            cameraSpeed = cameraSpeed * 0.98;
        }

        if (Math.abs(cameraY - followCarY) < 50) {
            cameraSpeed = cameraSpeed * 0.998;
        }

    }

    if (cameraSpeed > 10) {
        cameraSpeed = 10;
    }

    if (cameraSpeed < -10) {
        cameraSpeed = -10;
    }



    //cameraSpeed *= 0.8;

    prevCameraSpeed = cameraSpeed;
    cameraY += cameraSpeed;
    //
}

function updateCameraX() {

    if (followCar) {

        //console.log(cameraY, followCar.y, cameraY - followCar.y, cameraSpeed);

        if (cameraY < followCar.y) {
            cameraSpeed += 0.05;
        }
        if (cameraY > followCar.y) {
            cameraSpeed -= 0.05;
        }

        if (Math.abs(cameraY - followCar.y) < 100) {
            cameraSpeed = cameraSpeed * 0.98;
            //console.log("Dampening...", cameraSpeed, Math.abs(cameraY - followCar.y));
        }

        if (Math.abs(cameraY - followCar.y) < 10) {
            cameraSpeed = 0;
            //console.log("Stopping...", cameraSpeed, Math.abs(cameraY - followCar.y));
        }
    

    }

    if (cameraSpeed > 10) {
        cameraSpeed = 10;
    }

    if (cameraSpeed < -10) {
        cameraSpeed = -10;
    }



    //cameraSpeed *= 0.8;

    prevCameraSpeed = cameraSpeed;
    cameraY += cameraSpeed;
    //
}

var followSwitch = 2 * fps;
function setFollowCar(newFollowCar) {

    if (newFollowCar == followCar) {
        followSwitch = 2 * fps;
    } else {
        followSwitch--;
    }

    //followSwitch--;

    if (followSwitch <= 0) {
        followCar = newFollowCar;
        followSwitch = 2 * fps;
    }

    if (followCar) {
        followCar.following = true;
    }

}



function update(step) {

    for (let i = 0; i < traffic.length; i++) {
        traffic[i].update(road.borders, []);    
    }

    for (let i = 0; i < cars.length; i++) {
        cars[i].update(road.borders, traffic, step);   
    }
    
    frontCar = cars.find(c => c.y == Math.min(...cars.map(c => c.y)));

    //setFollowCar(frontCar);



    if (GameMode == "T") {
        bestCar = frontCar;
        //bestCar = cars.find(c => c.score == Math.min(...cars.map(c => c.y)));
        if (bestCar) {
            leadCarDiv.innerHTML = bestCar.id + " (" + bestCar.score + " / " + bestCar.passed + ")";
        } else {
            leadCarDiv.innerHTML = "";
        }
    } else if (GameMode == "C") {
        if (frontCar) {
            bestCar = frontCar;
            leadCarDiv.innerHTML = bestCar.id;
        }
    } else if (GameMode == "RL") {
        NeuralNetwork.feedForward(cars[0].offsets, starterBrain);
    }

    // if (bestCar && !bestCar.damaged) {
    //     setFollowCar(bestCar);
    // } else {
    //     setFollowCar(cars.find(c => c.y == Math.min(...cars.map(c => c.y))));
    // }

    //debugger;
    var aliveCars = cars.filter(c => !c.damaged);
    //setFollowCar(aliveCars.find(c => c.y == Math.min(...aliveCars.map(c => c.y))));

    // if (aliveCars.length > 1) {
    //     aliveCars.sort(function(a, b){return a.y - b.y})
    //     followCar = aliveCars[1];
    // }

    //console.log("followCar", aliveCars.length, bestCar.id, followCar.id);
    
    //followCar = aliveCars.find(c => c.y == Math.max(...aliveCars.map(c => c.y)));

    // if (!newBestCar)
    // {
    //     bestCar = newBestCar;
    //     //bestCar = cars.find(c => c.y == Math.min(...cars.map(c => c.y)));
        
    // }

    let liveCount = 0;
    for (let i = 0; i < cars.length; i++) {
        if (!cars[i].damaged) {
            liveCount++;
        }  
    }





    if (followSwitch <= 0 && GameMode != 'RL') {

        cars.forEach(car => {
            car.following = false;
            });

        followCar = aliveCars.find(c => c.y == Math.min(...aliveCars.map(c => c.y)));
    
        if (followCar) {
            
            followCar.following = true;
        }

        followSwitch = 2 * fps;
    } else {
        followSwitch--;
    }




    


    return liveCount;
}

function draw(time, catchup = false) {

    //console.log(bestCar);

    carCanvas.height = window.innerHeight;
    networkCanvas.height = 600;
    starterCanvas.height = 600;


    carCtx.translate(0, -cameraY + carCanvas.height * 0.7);

    road.draw(carCtx);
    

    
    if (GameMode == "T") {

        for (let i = 0; i < traffic.length; i++) {
            traffic[i].draw(carCtx);    
        }

        carCtx.save();
        if (true) {
            carCtx.globalAlpha = 0.4;

            for (let i = cars.length - 1; i >= 0; i--) {
                cars[i].draw(carCtx, false);
            }

            carCtx.globalAlpha = 1;
            

        }

        if (followCar) {
            followCar.draw(carCtx, true);
        }
        carCtx.restore();


    } else if (GameMode == "C") {

        for (let i = 0; i < traffic.length; i++) {
            traffic[i].draw(carCtx);    
        }

        for (let i = cars.length - 1; i >= 0; i--) {
            cars[i].draw(carCtx, false);
        }
    } else if (GameMode == "RL") {


        for (let i = 0; i < traffic.length; i++) {
            if (traffic[i].seen) {
                traffic[i].draw(carCtx);    
            }
        }

        for (let i = cars.length - 1; i >= 0; i--) {
            cars[i].draw(carCtx, i == 0);
        }
    }


    if (GameMode == "RL") {
        Visualizer.drawNetwork(networkCtx, cars[0].brain, cars[0].id, cars[0].color);
        Visualizer.drawNetwork(starterCtx, cars[1].brain, cars[1].id, cars[1].color);
    } else {
        if (followCar) {
            Visualizer.drawNetwork(networkCtx, followCar.brain, followCar.id, followCar.color);
        }
    }

    //requestAnimationFrame(animate);
}

function createPolygon(car) {
    const points = [];
    const rad = Math.hypot(car.width, car.height) / 2;
    const alpha = Math.atan2(car.width, car.height);
    points.push({x: car.x - Math.sin(car.angle - alpha) * rad, y: car.y - Math.cos(car.angle - alpha) * rad });
    points.push({x: car.x - Math.sin(car.angle + alpha) * rad, y: car.y - Math.cos(car.angle + alpha) * rad });
    points.push({x: car.x - Math.sin(Math.PI+car.angle - alpha) * rad, y: car.y - Math.cos(Math.PI+car.angle - alpha) * rad });
    points.push({x: car.x - Math.sin(Math.PI+car.angle + alpha) * rad, y: car.y - Math.cos(Math.PI+car.angle + alpha) * rad });

    return points;
}

function drawCar(car, strokeColor){
    carCtx.beginPath();

    carCtx.strokeStyle = strokeColor;

    var color = car.color;
    if (car.damaged) {
        color = "gray";
        carCtx.fillStyle = "gray";
        carCtx.strokeStyle = "gray";
    } else {
        carCtx.fillStyle = car.color;
    }

    // carCtx.fillStyle = color;
    // carCtx.strokeStyle = strokeColor;
    // carCtx.arc(car.x, car.y, 10, 0, Math.PI*2)
    // carCtx.globalAlpha = car.fuel;
    // carCtx.fill();
    // carCtx.stroke();
    // carCtx.globalAlpha = 1;

    const polygon = createPolygon(car);
    carCtx.beginPath();
    carCtx.moveTo(polygon[0].x, polygon[0].y);
    for (let i = 1; i < polygon.length; i++) {
        carCtx.lineTo(polygon[i].x, polygon[i].y);
    }
    carCtx.closePath();

    carCtx.lineWidth = 1;
    carCtx.stroke();
    
    if (car.damaged) {
        carCtx.globalAlpha = 0.2;
    } else {
        carCtx.globalAlpha = 0.2 + car.fuel * 0.8;
    }
    carCtx.fill()









    carCtx.globalAlpha = 0.5;

    if (car.sensor && car.sensor.rays) {
        for (let i = 0; i < car.sensor.rays.length; i++) {
            let end = car.sensor.rays[i].end;
            if (car.sensor.readings && car.sensor.readings[i]) {
                end = car.sensor.readings[i];
            }
            carCtx.beginPath();
            carCtx.lineWidth = 2;
            carCtx.strokeStyle = "darkgray";
            carCtx.moveTo(car.sensor.rays[i].start.x, car.sensor.rays[i].start.y);
            carCtx.lineTo(end.x, end.y);
            carCtx.stroke();

            carCtx.beginPath();
            carCtx.lineWidth = 2;
            carCtx.strokeStyle = "gray";
            carCtx.moveTo(car.sensor.rays[i].end.x, car.sensor.rays[i].end.y);
            carCtx.lineTo(end.x, end.y);
            carCtx.stroke();
        }
    }

    carCtx.globalAlpha = 1;


}

function drawSandbox(sb) {

    //console.log(bestCar);
    if (!sb) {
        return;
    }

    if (!sb.cars) {
        return;
    }

    carCanvas.height = window.innerHeight;
    networkCanvas.height = 600;

    carCtx.translate(0, -cameraY + carCanvas.height * 0.7);

    road.draw(carCtx);
    
    for (let i = 0; i < sb.cars.length; i++) {
        var car = sb.cars[i];
        
        drawCar(car, car.straight ? "red" : "white");

        // if (car.polygon && car.polygon.vectorList && car.polygon.vectorList.length > 1) {
        //     carCtx.beginPath();
        //     carCtx.moveTo(car.polygon.vectorList[0].x, car.polygon.vectorList[0].y);
        //     for (let i = 1; i < car.polygon.vectorList.length; i++) {
        //         carCtx.lineTo(car.polygon.vectorList[i].x, car.polygon.vectorList[i].y);
        //     }
        //     carCtx.closePath();

        //     if (car.damaged) {
        //         carCtx.fillStyle = "gray";
        //     } else {
        //         carCtx.fillStyle = car.color;
        //     }

        //     carCtx.strokeStyle = "white";

        //     carCtx.lineWidth = 2;
        //     carCtx.stroke();

        //     carCtx.fill();
        // }


        //traffic[i].draw(carCtx);    
    }


    for (let i = 0; i < sb.traffic.length; i++) {
        var car = sb.traffic[i];
        drawCar(car, "red");
        // if (car.polygon && car.polygon.vectorList && car.polygon.vectorList.length > 1) {
        //     carCtx.beginPath();
        //     carCtx.moveTo(car.polygon.vectorList[0].x, car.polygon.vectorList[0].y);
        //     for (let i = 1; i < car.polygon.vectorList.length; i++) {
        //         carCtx.lineTo(car.polygon.vectorList[i].x, car.polygon.vectorList[i].y);
        //     }
        //     carCtx.closePath();

        //     carCtx.fillStyle = car.color;
        //     carCtx.strokeStyle = "red";

        //     carCtx.lineWidth = 2;
        //     carCtx.stroke();

        //     carCtx.fill();
        // }


        //traffic[i].draw(carCtx);
        
        


    }


    if (sb.sensor) {
        for (let i = 0; i < sb.sensor.rays.length; i++) {
            let end = sb.sensor.rays[i].end;
            if (sb.sensor.readings && sb.sensor.readings[i]) {
                end = sb.sensor.readings[i];
            }
            carCtx.beginPath();
            carCtx.lineWidth = 2;
            carCtx.strokeStyle = "yellow";
            carCtx.moveTo(sb.sensor.rays[i].start.x, sb.sensor.rays[i].start.y);
            carCtx.lineTo(end.x, end.y);
            carCtx.stroke();

            carCtx.beginPath();
            carCtx.lineWidth = 2;
            carCtx.strokeStyle = "gray";
            carCtx.moveTo(sb.sensor.rays[i].end.x, sb.sensor.rays[i].end.y);
            carCtx.lineTo(end.x, end.y);
            carCtx.stroke();
        }
    }


    // for (let i = 0; i < traffic.length; i++) {
    //     traffic[i].draw(carCtx);    
    // }
    
    // if (GameMode == "T") {

    //     carCtx.save();
    //     if (true) {
    //         carCtx.globalAlpha = 0.4;

    //         for (let i = cars.length - 1; i >= 0; i--) {
    //             cars[i].draw(carCtx);
    //         }

    //         carCtx.globalAlpha = 1;
            

    //     }

    //     if (followCar) {
    //         followCar.draw(carCtx, true);
    //     }
    //     carCtx.restore();


    // } else if (GameMode == "C") {
    //     for (let i = cars.length - 1; i >= 0; i--) {
    //         cars[i].draw(carCtx, false);
    //     }
    // }

    //debugger;
    if (sb.neuralNetwork) {
        Visualizer.drawNetwork(networkCtx, sb.neuralNetwork, sb.followCarId, sb.followCarColor);
    }

    //requestAnimationFrame(animate);
}



// START OF CODE


token = localStorage.getItem("token");

if (localStorage.getItem("carCount")) {
    dropDownListCarCount.value = localStorage.getItem("carCount");
}

dropDownListCarCount.addEventListener("change", (event) => { 
    localStorage.setItem('carCount', dropDownListCarCount.value);
});



console.log("Game Mode", GameMode);
if (local) {
    //debugger;
    console.log("Running Local Version");
    if (GameMode == "C") {
        if (true) {
            setupCompetition();
        } else {
            reset();
            requestAnimationFrame(_frameCallback);
        }


    } else if (GameMode == "T") {
        reset();
        requestAnimationFrame(_frameCallback);
    } else if (GameMode == "RL") {
        reset();
        requestAnimationFrame(_frameCallback);
    }
}
else {
    console.log("Running Network Version");
    newSanbox();
    reset();
}




if (GameMode == "T") {
    legendDiv.style.display = "";
    generationNameSpan.innerHTML = "Generation";
} else if (GameMode == "RL") {
    rllegendDiv.style.display = "";
    starterCanvas.style.display = "";
} else {
    generationNameSpan.innerHTML = "Race";
}
